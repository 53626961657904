import React from 'react'

const GridIcon = (props: {color?: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
    >
      <path
        d='M14.2087 3.66699V14.3003H3.66699C3.66699 7.68199 7.60866 3.70366 14.2087 3.66699Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M40.3337 14.3003H29.792V3.66699C36.392 3.70366 40.3337 7.68199 40.3337 14.3003Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M40.3337 29.8838C40.2603 36.3921 36.337 40.2971 29.792 40.3338V29.8838H40.3337Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M14.2087 29.8838V40.3338C7.66366 40.2971 3.74033 36.3921 3.66699 29.8838H14.2087Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M14.2087 17.0498H3.66699V27.1331H14.2087V17.0498Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M40.3337 17.0498H29.792V27.1331H40.3337V17.0498Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M27.0413 17.0498H16.958V27.1331H27.0413V17.0498Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M27.0413 3.66699H16.958V14.3003H27.0413V3.66699Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M27.0413 29.8838H16.958V40.3338H27.0413V29.8838Z'
        fill={props.color || '#9AA4B2'}
      />
    </svg>
  )
}

export default GridIcon
