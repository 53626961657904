import {BlogType} from '../types/blog.type'
import {GetParamsType} from '../types/getParams.type'
import axiosClient from './axiosClient'
import {BLOG} from './urlConfig'

export const blogApi = {
  create: (payload: BlogType) => {
    return axiosClient.post(BLOG, payload)
  },
  get: (params: GetParamsType) => {
    return axiosClient.get(BLOG, {params})
  },
  getDetail: (id: number) => {
    return axiosClient.get(`${BLOG}/${id}`)
  },
  update: (payload: BlogType) => {
    const id = payload.id
    let newObject: any = {...payload}
    delete newObject.id
    return axiosClient.put(`${BLOG}/${id}`, newObject)
  },
  delete: async (id: number) => {
    return await axiosClient.delete(`${BLOG}/${id}`)
  },
  blogOrder: (id: number, type: 'UP' | 'DOWN') => {
    return axiosClient.put(`${BLOG}/order/${id}`, {type})
  },
}
