export const LOGIN = '/auth/login'
export const LOGOUT = ''

//upload file
export const UPLOAD_IMAGE = '/system/upload-image'
export const UPLOAD_IMAGES = '/system/multiple-upload'
export const UPLOAD_fILE = '/system/upload-file'
export const UPLOAD_S3 = '/system/upload-s3'

//Portfolios

export const PORTFOLIO = '/portfolio'

//order_project

export const ORDER_PROJECT = '/order-project'

//option

export const OPTION = '/option'

//tag

export const TAG = '/tag'

//type
export const TYPE = '/type'

//blog
export const BLOG = '/blog'

//category
export const CATEGORY = '/category'
