import {makeStyles} from '@material-ui/core'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import EditIcon from '../../asset/icons/Edit'
import GridIcon from '../../asset/icons/GridIcon'
import CardBlog from '../../components/card_blog'
import {blogAction, selectListBlog} from '../../feature/blog/blogSlice'
import CreateBlog from './create'

const useStyles = makeStyles({
  blog_container: {
    display: 'flex',
    background: 'white',
    minHeight: '100vh',
  },
  box_1: {
    width: '88px',
    boxSizing: 'border-box',
    background: 'white',
    borderRight: '1px solid var(--gray-200, #E3E8EF)',
    '&>div': {
      padding: '12px 16px 12px 0px',
      display: 'flex',
      height: '90px',
      alignItems: 'center',
      gap: '16px',
      boxSizing: 'border-box',
    },
  },
  box_2: {
    width: 'calc(100% - 88px)',
    padding: '32px 66px 32px 66px',
    overflow: 'auto',
    maxHeight: '100vh',
  },
  box_3: {
    width: 'calc(100% - 88px)',
    background: 'white',
    padding: '32px 80px 32px 80px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    overflow: 'auto',
    maxHeight: '100vh',
  },
  border_active: {
    height: '66px',
    width: '4px',
    borderRadius: '0px var(--32px, 32px) var(--32px, 32px) 0px',
    background: 'var(--primary-500, #2563EB)',
  },
  border_no_active: {
    height: '4px',
    width: '4px',
    borderRadius: '0px var(--32px, 32px) var(--32px, 32px) 0px',
    background: '#D9D9D9',
  },
  image_style: {
    width: '44px',
    height: '44px',
  },
})
const Blog = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const listBlog = useAppSelector(selectListBlog)
  const [searchParams, setSearchParams] = useSearchParams()
  const state = searchParams.get('state')
  useEffect(() => {
    dispatch(blogAction.get({page: 1, perPage: 1000}))
  }, [dispatch])

  console.log(888, listBlog)
  return (
    <div className={classes.blog_container}>
      {' '}
      <div className={classes.box_1}>
        <div
          style={{background: state === '1' ? '#DBE6FE' : 'white'}}
          onClick={() => {
            searchParams.set('state', '1')
            searchParams.delete('id')
            setSearchParams(searchParams)
          }}
        >
          <div
            className={
              state === '1' ? classes.border_active : classes.border_no_active
            }
          ></div>
          <div>
            <EditIcon color={state === '1' ? '#2563EB' : '#9AA4B2'} />
            <span
              style={{
                fontSize: '14px',
                color: state === '1' ? '#2563EB' : '#9AA4B2',
              }}
            >
              글작성
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            searchParams.set('state', '2')
            searchParams.delete('id')
            setSearchParams(searchParams)
          }}
          style={{background: state === '2' ? '#DBE6FE' : 'white'}}
        >
          <div
            className={
              state === '2' ? classes.border_active : classes.border_no_active
            }
          ></div>
          <div>
            <GridIcon color={state === '2' ? '#2563EB' : '#9AA4B2'} />
            <span
              style={{
                fontSize: '14px',
                color: state === '2' ? '#2563EB' : '#9AA4B2',
              }}
            >
              목록열기
            </span>
          </div>
        </div>
      </div>
      {state === '1' && (
        <div className={classes.box_2}>
          <CreateBlog />
        </div>
      )}
      {state === '2' && (
        <div className={classes.box_3}>
          {listBlog.map((item) => (
            <CardBlog
              category={item.category as any}
              content={item.content}
              title={item.title}
              thumbnail={item.thumbnail}
              key={item.id}
              id={item.id.toString()}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Blog
