import {IconButton, Menu, MenuItem} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {useState} from 'react'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import {useSearchParams} from 'react-router-dom'
import {blogApi} from '../../apis/blogApi'
import {useAppDispatch} from '../../app/hooks'
import {blogAction} from '../../feature/blog/blogSlice'
import {snackBarActions} from '../snackbar/snackbarSlice'

interface CardBlogProps {
  thumbnail?: string
  title?: string
  content?: string
  category?: {id: string; name: string}
  id?: string
}

const CardBlog = ({thumbnail, title, content, category, id}: CardBlogProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDelete = async () => {
    const res: any = await blogApi.delete(Number(id))
    if (res.success) {
      dispatch(blogAction.get({page: 1, perPage: 1000}))
      dispatch(
        snackBarActions.setStateSnackBar({content: 'success', type: 'success'})
      )
      handleClose()
    } else {
      dispatch(
        snackBarActions.setStateSnackBar({content: 'error', type: 'error'})
      )
      handleClose()
    }
  }
  const handleBlogOrder = async (type: 'UP' | 'DOWN') => {
    const res: any = await blogApi.blogOrder(Number(id), type)
    if (res.success) {
      dispatch(blogAction.get({page: 1, perPage: 1000}))
      dispatch(
        snackBarActions.setStateSnackBar({content: 'success', type: 'success'})
      )
      handleClose()
    } else {
      dispatch(
        snackBarActions.setStateSnackBar({content: 'error', type: 'error'})
      )
      handleClose()
    }
  }
  return (
    <div className='flex justify-between items-start border-[1px] rounded-2xl p-4'>
      <div className='flex gap-6 items-center'>
        <img className='min-w-[146px] w-[146px] h-[133px] rounded-lg object-cover' alt='thumbnail' src={thumbnail} />
        <div className='flex flex-col gap-2'>
          <p className='font-semibold text-[15px] leading-[22px]'>
            {category?.name}
          </p>
          <p className='font-bold text-lg line-clamp-2'>{title}</p>
          <p
            className='text-[15px] line-clamp-3 max-h-[66px] overflow-auto'
            style={{
              display: '-webkit-box',
              maxWidth: '100%',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            dangerouslySetInnerHTML={{__html: content || ''}}
          />
        </div>
      </div>

      <IconButton onClick={handleClickPopup}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='!shadow-2xl'
      >
        <MenuItem
          onClick={() => {
            handleBlogOrder('UP')
          }}
        >
          <ArrowUpwardIcon /> 위로이동
        </MenuItem>
        <div className='border-[1px] border-[#FAFAFA]'></div>
        <MenuItem
          onClick={() => {
            handleBlogOrder('DOWN')
          }}
        >
          <ArrowDownwardIcon /> 아래로 이동
        </MenuItem>
        <div className='border-[1px] border-[#FAFAFA]'></div>
        <MenuItem
          onClick={() => {
            handleDelete()
          }}
        >
          <DeleteOutlineOutlinedIcon /> 삭제
        </MenuItem>
        <div className='border-[1px] border-[#FAFAFA]'></div>
        <MenuItem
          onClick={() => {
            searchParams.set('state', '1')
            searchParams.set('id', id || '')
            setSearchParams(searchParams)
          }}
        >
          <EditOutlinedIcon /> 수정
        </MenuItem>
      </Menu>
    </div>
  )
}

export default CardBlog
