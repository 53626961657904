import {CategoryType} from '../types/category.type'
import {GetParamsType} from '../types/getParams.type'
import axiosClient from './axiosClient'
import {CATEGORY} from './urlConfig'

export const categoryApi = {
  create: (payload: CategoryType) => {
    return axiosClient.post(CATEGORY, payload)
  },
  get: (params: GetParamsType) => {
    return axiosClient.get(CATEGORY, {params})
  },
  getDetail: (id: number) => {
    return axiosClient.get(`${CATEGORY}/${id}`)
  },
  update: (payload: CategoryType) => {
    return axiosClient.put(`${CATEGORY}/${payload.id}`, payload)
  },
  delete: async (ids: number[]) => {
    return await axiosClient.delete(CATEGORY, {data: {ids}})
  },
}
