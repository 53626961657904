import React, {useRef, useCallback, useMemo} from 'react'
import JoditEditor from 'jodit-react'

const JoditEditorCustom = React.memo(
  (props: {content: string; setContent: (e: string) => void}) => {
    const editor = useRef(null)
    const customPrepareData = useCallback((formData: any) => {
      const data: any[] = []
      for (const value of formData.values()) {
        if (value instanceof File) data.push(value)
      }
      const keys = []
      for (const key of formData.keys()) {
        keys.push(key)
      }
      keys.forEach((item) => {
        formData.delete(item)
      })
      data.forEach((item) => formData.append('pictures', item))
      return formData
    }, [])

    const config: any = useMemo(
      () => ({
        placeholder: '블로그나 기사를를 입력합니다.',
        uploader: {
          insertImageAsBase64URI: false,
          url: 'https://server.greenapps.kr/system/multiple-s3',
          method: 'POST',
          prepareData: customPrepareData,
          isSuccess: (response: any) => response.code === 0,
          getMsg: (response: any) => response.msg || 'success',
          process: (response: any) => {
            response = {
              files: response.data.map((file: any) => ({
                filename: file.filename,
                path: file.completedUrl,
              })),
            }
            return response
          },
          defaultHandlerSuccess: (data: any, response: any) => {
            let imageHtml = ''
            if (data && data.files && data.files.length > 0) {
              data.files.forEach((item: any) => {
                const imageUrl = item.path
                imageHtml =
                  imageHtml + `<img src="${imageUrl}" alt="Uploaded Image" />`
              })
            }
            props.setContent(props.content + imageHtml)
          },
        },
      }),
      [customPrepareData, props.content]
    )

    return (
      <JoditEditor
        ref={editor}
        value={props.content}
        config={config}
        onBlur={(newContent) => props.setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        // onChange={(newContent) => {}}
      />
    )
  }
)

export default JoditEditorCustom
