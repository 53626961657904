import React from 'react'

const EditIcon = (props: {color?: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
    >
      <path
        d='M20.9005 33.2958L20.9005 33.2958L34.4596 19.7367C32.6142 18.9687 30.4285 17.707 28.3614 15.6399C26.2939 13.5725 25.0322 11.3864 24.2642 9.54081L10.7048 23.1001L10.7048 23.1002C9.64671 24.1583 9.11763 24.6873 8.66264 25.2707C8.12591 25.9588 7.66574 26.7034 7.29028 27.4912C6.972 28.159 6.7354 28.8689 6.26219 30.2885L3.76681 37.7746C3.53394 38.4732 3.71577 39.2434 4.23648 39.7642C4.7572 40.2849 5.52742 40.4667 6.22603 40.2338L13.7122 37.7385C15.1318 37.2653 15.8416 37.0286 16.5094 36.7104C17.2973 36.3349 18.0418 35.8747 18.73 35.338C19.3133 34.883 19.8424 34.3539 20.9005 33.2958Z'
        fill={props.color || '#9AA4B2'}
      />
      <path
        d='M38.2221 15.9743C41.0375 13.1588 41.0375 8.59404 38.2221 5.77859C35.4066 2.96313 30.8418 2.96313 28.0264 5.77859L26.4001 7.40482C26.4224 7.47207 26.4455 7.54025 26.4695 7.60931C27.0655 9.3274 28.1902 11.5797 30.3059 13.6954C32.4216 15.8111 34.6739 16.9357 36.392 17.5318C36.4607 17.5556 36.5286 17.5786 36.5955 17.6008L38.2221 15.9743Z'
        fill={props.color || '#9AA4B2'}
      />
    </svg>
  )
}

export default EditIcon
