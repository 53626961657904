export const HTTP_STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}
export const BASE_URL = 'https://server.greenapps.kr/'

export const LIST_TAG = [
  'UI_PAGE',
  'REGISTER_PAGE',
  'LOGIN_PAGE',
  'PROFILE_PAGE',
  'HOME_PAGE',
  'PAYMENT_PAGE',
  'SETTING_PAGE',
  'MESSAGE',
]
export const LIST_TYPE = ['UX_UI', 'APP', 'WEB', 'ADMIN_PAGE']
