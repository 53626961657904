import {makeStyles, TextareaAutosize} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {blogApi} from '../../../apis/blogApi'
import {CATEGORY} from '../../../apis/urlConfig'
import {useAppDispatch} from '../../../app/hooks'
import InputBase from '../../../components/input'
import InputSelectApi from '../../../components/input_select_api'
import UploadImage from '../../../components/upload_image'
import {blogAction} from '../../../feature/blog/blogSlice'
import {BlogType} from '../../../types/blog.type'
import {exportResults} from '../../../utils'
import JoditEditorCustom from '../../../components/JoditEditor'
import {Close} from '@material-ui/icons'

const useStyles = makeStyles({
  container_create_portfolio: {
    '&>div:nth-child(1)': {
      width: '100%',
      background: 'white',
      maxWidth: '922px',
      margin: 'auto',
      '&>div:nth-child(1)': {
        borderRadius: '12px 12px 0 0',
        display: 'flex',
        alignItem: 'center',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '27px',
        color: '#13191D',
        // background: '#F1F5F9',
        padding: '0px 1rem',
        '&>span': {
          marginRight: '1rem',
        },
      },
      '&>div:nth-child(2)': {
        padding: '1rem',
        '&>div': {
          '&>p': {
            marginBottom: '10px',
            marginTop: '10px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '27px',
            color: '#1F293',
          },
        },
      },
      '&>div:nth-child(3)': {
        textAlign: 'center',
        '&>button': {
          width: 'calc(100% - 2rem)',
          height: '48px',
          background: '#0065F2',
          borderRadius: '6px',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          color: '#FFFFFF',
          margin: '1rem 1rem 2rem',
        },
      },
    },
  },
})

const CreateBlog = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const searchParams = useSearchParams()
  const id = searchParams[0].get('id')
  const [tag, setTag] = useState<string>('')

  const [data, setData] = useState<any>({
    title: '',
    content: '',
    description: '',
    keywords: '',
    hashTags: [],
    images: [],
  })

  const handleBlog = () => {
    if (id) {
      dispatch(
        blogAction.update({
          data: {
            ...data,
            thumbnail: data.images,
            id: Number(id),
          },
          history: navigate,
        })
      )
    } else {
      const formDataImage = new FormData()
      formDataImage.append('picture', data.images[0])
      dispatch(
        blogAction.create({
          data: {...data, thumbnail: formDataImage},
          history: navigate,
        })
      )
    }
  }
  useEffect(() => {
    if (id) {
      const getDetail = async () => {
        const data = await blogApi.getDetail(Number(id))
        const blog: BlogType = exportResults(data)
        setData({
          images: [blog.thumbnail],
          title: blog.title,
          description: blog.description,
          keywords: blog.keywords,
          content: blog.content,
          hashTags: blog.hashTags.map((item: any) => item.name),
          categoryId: blog.category.id,
        })
      }
      getDetail()
    }
  }, [id])

  const validateField = () => {
    const {images, content, title, description, keywords} = data
    if (
      images.length === 0 ||
      !content ||
      !title ||
      !description ||
      !keywords
    ) {
      return true
    } else return false
  }

  return (
    <div className={classes.container_create_portfolio}>
      <div>
        <div>블로그 1개 쓰기</div>
        <div>
          <div>
            <InputSelectApi
              label='장르 선택'
              onChange={(e) => {
                setData({...data, categoryId: e})
              }}
              placeholder='장르 선택'
              url={CATEGORY}
              value={data.categoryId}
            />
            <br />
            <UploadImage
              images={data.images}
              setImages={(e) => setData({...data, images: e})}
            />
          </div>

          <InputBase
            onChange={(e) => setData({...data, title: e})}
            label='제목'
            placeholder='제목 입력'
            value={data.title}
          />

          <div style={{display: 'inherit'}}>
            <label
              style={{
                margin: '10px 0',
                display: 'inline-block',
                fontSize: '18px',
              }}
              htmlFor='textarea'
            >
              콘텐츠 작성
            </label>
            <br />
            <JoditEditorCustom
              content={data.content}
              setContent={(e) => setData({...data, content: e})}
            />
          </div>

          <div style={{display: 'inherit'}}>
            <label
              style={{
                margin: '10px 0',
                display: 'inline-block',
                fontSize: '18px',
              }}
              htmlFor='textarea'
            >
              메타설명
            </label>
            <br />
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              id='textarea'
              placeholder='설명입력'
              style={{
                width: '100%',
                paddingLeft: '10px',
                height: '144px',
                boxSizing: 'border-box',
                fontSize: '16px',
              }}
              className='border-[1px] border-[#A1A1A1] rounded-md pt-2'
              onChange={(e) => setData({...data, description: e.target.value})}
              value={data.description}
            />
          </div>
          {/* <InputBase
            onChange={(e) => {}}
            label='URL'
            placeholder='url슬러그 입력'
            value={data.url}
          /> */}

          <div style={{display: 'inherit'}}>
            <label
              style={{
                margin: '10px 0',
                display: 'inline-block',
                fontSize: '18px',
              }}
              htmlFor='textarea'
            >
              Keyworld
            </label>
            <br />
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              id='textarea'
              placeholder='키워드 입력'
              style={{
                width: '100%',
                paddingLeft: '10px',
                height: '144px',
                boxSizing: 'border-box',
                fontSize: '16px',
              }}
              className='border-[1px] border-[#A1A1A1] rounded-md pt-2'
              onChange={(e) => setData({...data, keywords: e.target.value})}
              value={data.keywords}
            />
          </div>
          <div>
            <InputBase
              label='해시태그'
              onChange={(e) => {
                setTag(e)
              }}
              value={tag}
              placeholder='# 입력'
              handleEnter={() => {
                if (tag) {
                  if (!data.hashTags.includes(tag)) {
                    const listTag = [...data.hashTags, tag]
                    setData({...data, hashTags: listTag})
                    setTag('')
                  }
                }
              }}
            />
            {data.hashTags && data.hashTags.length > 0 && (
              <div
                style={{
                  marginTop: '16px',
                  display: 'flex',
                  gap: '16px',
                  flexWrap: 'wrap',
                }}
              >
                {data.hashTags.map((item: string) => (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#F9F9F9',
                      padding: '10px 16px',
                      height: '44px',
                      boxSizing: 'border-box',
                      width: 'fit-content',
                      borderRadius: '100px',
                      color: '#353945',
                      fontWeight: 700,
                      fontSize: '14px',
                    }}
                    key={item}
                  >
                    #{item} &nbsp;
                    <span
                      onClick={() => {
                        const listHashtag = data.hashTags
                        const listHashtagNew = listHashtag?.filter(
                          (itemNew: string) => item !== itemNew
                        )
                        setData({...data, hashTags: listHashtagNew})
                      }}
                      style={{width: '24px', height: '24px'}}
                    >
                      <Close style={{color: '#767D8F', cursor: 'pointer'}} />
                    </span>
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <Button
            onClick={handleBlog}
            disabled={validateField()}
            style={validateField() ? {background: '#80B2F9'} : {}}
          >
            올리기
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateBlog
