import {makeStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {useEffect, useState} from 'react'
import {categoryApi} from '../../apis/categoryApi'
import {CATEGORY} from '../../apis/urlConfig'
import useClickOutside from '../../hooks/useClickOutside'

const useStyles = makeStyles({
  container_input_select_api: {
    width: '100%',
    position: 'relative',
    '&>label': {
      display: 'inline-block',
      marginBottom: '10px',
      marginTop: '10px',
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '27px',
      color: '#1F293',
      '&>span': {
        color: '#F22828',
      },
    },
    '&>input': {
      width: '100%',
      height: '42px',
      fontFamily: 'Pretendard',
      paddingLeft: '10px',
      border: '1px solid #9CA3AF',
      borderRadius: '4px',
      boxSizing: 'border-box',
      fontSize: '16px',
    },
    '&>svg': {
      position: 'absolute',
      top: '55px',
      right: '7px',
    },
    '&>div': {
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2)',
      padding: '0.5rem 0',
      width: '100%',
      position: 'absolute',
      background: 'white',
      zIndex: 100,
      '&>p': {
        padding: '0.5rem 1rem',
        margin: '0',
      },
      '&>p:hover': {
        background: '#E5E7EB',
      },
    },
  },
})

const InputSelectApi = (props: {
  label: string
  placeholder: string
  require?: boolean
  onChange: (event: any) => void
  type?: 'text' | 'number'
  value?: string
  url?: string
}) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [value, setValue] = useState<{id: any; value: string}>()
  const handleSelect = (params: any) => {
    props.onChange(params.value)
    setValue(params)
    setShow(false)
  }
  const [listData, setListData] = useState<{id: any; value: string}[]>([])
  const getListData: any = async (url: string) => {
    if (url === CATEGORY) {
      const res = await categoryApi.get({})
      setListData([
        ...res.data.map((item: any) => ({id: item.name, value: item.id})),
      ])
      return res
    }
  }
  useEffect(() => {
    if (props.url) {
      getListData(props.url)
    }
  }, [props.url])

  useEffect(() => {
    if (props.value && listData.length > 0) {
      const data = listData.find((item) => item.value === props.value)
      setValue(data)
    }
  }, [props.value, listData])
  const handleClickOutside = () => {
    setShow(false)
  }
  const divRef = useClickOutside<HTMLDivElement>(handleClickOutside)

  return (
    <div className={classes.container_input_select_api}>
      <label htmlFor={props.label}>
        {props.label} {props.require && <span>*</span>}
      </label>
      <br />
      <input
        type={props.type ? props.type : 'text'}
        id={props.label}
        placeholder={props.placeholder}
        required={props.require}
        onClick={() => setShow(true)}
        value={value?.id}
        name={value?.value}
      />
      <ExpandMoreIcon />
      <div ref={divRef} style={!show ? {display: 'none'} : {}}>
        {listData &&
          listData.map((item) => (
            <p
              key={item.value}
              onClick={() => {
                console.log(item.value)
                handleSelect(item)
              }}
              style={value?.value === item.value ? {background: '#E5E7EB'} : {}}
            >
              {item.id}
            </p>
          ))}
      </div>
    </div>
  )
}

export default InputSelectApi
