import {PayloadAction} from '@reduxjs/toolkit'
import {NavigateFunction} from 'react-router-dom'
import {call, put, takeEvery} from 'redux-saga/effects'
import {blogApi} from '../../apis/blogApi'
import {uploadApi} from '../../apis/uploadApi'
import {loadingActions} from '../../components/loading/loadingSlice'
import {snackBarActions} from '../../components/snackbar/snackbarSlice'
import {BlogType} from '../../types/blog.type'
import {GetParamsType} from '../../types/getParams.type'
import {blogAction} from './blogSlice'
import {ROUTE} from '../../router/routes'

function* createBlog(
  action: PayloadAction<{data: any; history: NavigateFunction}>
) {
  try {
    yield put(loadingActions.openLoading())
    const dataImage: {
      data: {completedUrl: string; filename: string}
    } = yield call(
      uploadApi.uploadS3,
      action.payload.data.thumbnail as FormData
    )
    const data: {
      code: number
    } = yield call(blogApi.create, {
      title: action.payload.data.title,
      content: action.payload.data.content,
      description: action.payload.data.description,
      keywords: action.payload.data.keywords,
      hashTags: action.payload.data.hashTags,
      categoryId: action.payload.data.categoryId,
      thumbnail: dataImage.data.completedUrl,
    } as any)
    if (data.code === 0) {
      yield put(loadingActions.loadingSuccess())
      yield put(
        snackBarActions.setStateSnackBar({content: 'success', type: 'success'})
      )
      yield put(blogAction.get({page: 1, perPage: 1000}))
      action.payload.history(`${ROUTE.BLOG}?state=2`)
    } else {
      yield put(loadingActions.loadingSuccess())
      yield put(
        snackBarActions.setStateSnackBar({content: 'error', type: 'error'})
      )
    }
  } catch (error) {
    yield put(loadingActions.loadingSuccess())

    yield put(
      snackBarActions.setStateSnackBar({
        content: JSON.stringify(error),
        type: 'error',
      })
    )
  }
}

function* getBlog(action: PayloadAction<GetParamsType>) {
  try {
    const data: {
      code: number
      data: {listBlog: BlogType[]; total: number}
    } = yield call(blogApi.get, action.payload)
    if (data.code === 0) {
      yield put(blogAction.getSuccess({data: data.data}))
    } else {
      yield put(
        snackBarActions.setStateSnackBar({content: 'error', type: 'error'})
      )
    }
  } catch (error) {
    console.log(error)
  }
}

function* updateBlog(
  action: PayloadAction<{data: any; history: NavigateFunction}>
) {
  try {
    let thumbnail: string = action.payload.data.thumbnail[0]
    if (typeof action.payload.data.thumbnail[0] === 'object') {
      const formdataImage = new FormData()
      formdataImage.append('picture', thumbnail)
      const dataImage: {data: {completedUrl: string; filename: string}} =
        yield call(uploadApi.uploadS3, formdataImage)
      thumbnail = dataImage.data.completedUrl
    }

    yield call(blogApi.update, {
      id: action.payload.data.id,
      title: action.payload.data.title,
      content: action.payload.data.content,
      description: action.payload.data.description,
      keywords: action.payload.data.keywords,
      hashTags: action.payload.data.hashTags,
      categoryId: action.payload.data.categoryId,
      thumbnail: thumbnail,
    } as any)
    yield put(blogAction.get({page: 1, perPage: 1000}))

    yield put(
      snackBarActions.setStateSnackBar({content: 'success', type: 'success'})
    )
    action.payload.history(`${ROUTE.BLOG}?state=2`)
  } catch (error) {
    yield put(
      snackBarActions.setStateSnackBar({
        content: JSON.stringify(error),
        type: 'error',
      })
    )
  }
}

export default function* blogSaga() {
  yield takeEvery(blogAction.create.type, createBlog)
  yield takeEvery(blogAction.get.type, getBlog)
  yield takeEvery(blogAction.update.type, updateBlog)
}
