import React from 'react'

const IconFirstLine = (props: {color?: string}) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.666 3.75H17.4993'
        stroke={props.color ? props.color : '#4D4D4D'}
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M11.666 7.91602H17.4993'
        stroke={props.color ? props.color : '#4D4D4D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.5 12.084H17.5'
        stroke={props.color ? props.color : '#4D4D4D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.91602 7.02565V4.64232C7.91602 3.70898 7.54102 3.33398 6.59935 3.33398H4.22435C3.29102 3.33398 2.91602 3.70898 2.91602 4.64232V7.01732C2.91602 7.95898 3.29102 8.33398 4.22435 8.33398H6.59935C7.54102 8.33398 7.91602 7.95898 7.91602 7.02565Z'
        stroke={props.color ? props.color : '#4D4D4D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.375 16.25H17.5'
        stroke={props.color ? props.color : '#4D4D4D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.5 16.25H4.98333'
        stroke={props.color ? props.color : '#4D4D4D'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default IconFirstLine
