import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NavigateFunction} from 'react-router-dom'
import {RootState} from '../../app/store'
import {BlogType} from '../../types/blog.type'
import {GetParamsType} from '../../types/getParams.type'

interface State {
  listBlog: BlogType[]
  loading: boolean
  total?: number
}

const initialState: State = {
  listBlog: [],
  loading: false,
}

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    create: (
      state,
      action: PayloadAction<{data: any; history: NavigateFunction}>
    ) => {},
    update: (
      state,
      action: PayloadAction<{data: any; history: NavigateFunction}>
    ) => {},
    get: (state, action: PayloadAction<GetParamsType>) => {
      state.loading = true
    },
    getSuccess: (
      state,
      action: PayloadAction<{
        data: {listBlog: BlogType[]; total: number}
      }>
    ) => {
      state.listBlog = action.payload.data.listBlog
      state.total = action.payload.data.total
      state.loading = false
    },
    getFail: (state) => {
      state.loading = false
    },
  },
})

export const blogAction = blogSlice.actions

export const blogReducer = blogSlice.reducer

export const selectListBlog = (state: RootState) => state.blogReducer.listBlog

export const selectTotalBlog = (state: RootState) => state.blogReducer.total
